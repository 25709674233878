import { ref, watch, computed } from '@vue/composition-api';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useUtils from '@/composables/useUtils';
import useTour from '@/modules/frontier-tour/composables/useTour';

export default function useTourList() {
  const toast = useToast();
  const { formatCurrency, convertUtcToLocalDate, formatDateDdMmYyyyHi } = useUtils();
  const { getTours } = useTour();

  const perPageOptions = [10, 25, 50, 100];
  const tableColumns = [
    { key: 'voucherNum', sortable: true },
    { key: 'tourType' },
    { key: 'tourStatusId', label: 'status' },
    {
      key: 'tourTotalFee',
      label: 'total',
      sortable: true,
      formatter: (val) => formatCurrency(val),
    },
    {
      key: 'dateAddedUtc',
      label: 'date added',
      sortable: true,
      sortable: true,
      formatter: (val) => `${formatDateDdMmYyyyHi(convertUtcToLocalDate(val))}`,
    },
    { key: 'actions' },
  ];

  const tourTypeFilter = ref(null);
  const tourStatusFilter = ref(null);
  const pageNumber = ref(1);
  const orderDesc = ref(true);
  const pageSize = ref(10);
  const refTourListTable = ref(null);
  const searchQuery = ref(null);
  const searchByDate = ref('');
  const orderBy = ref('id');
  const totalTours = ref(0);

  const dataMeta = computed(() => {
    const localItemsCount = refTourListTable.value
      ? refTourListTable.value.localItems.length
      : 0;
    return {
      from: pageSize.value * (pageNumber.value - 1) + (localItemsCount ? 1 : 0),
      to: pageSize.value * (pageNumber.value - 1) + localItemsCount,
      of: totalTours.value,
    };
  });

  watch(
    [pageNumber, pageSize, tourTypeFilter, tourStatusFilter, searchByDate],
    () => {
      refTourListTable.value.refresh();
    }
  );

  const clearSearchByDate = () => {
    searchByDate.value = '';
  };

  const fetchTours = (ctx, callback) => {
    const params = {
      searchQuery: searchQuery.value,
      searchByDate: searchByDate.value === '' ? null : searchByDate.value,
      pageSize: pageSize.value,
      pageNumber: pageNumber.value,
      orderBy: orderBy.value,
      orderDesc: orderDesc.value,
      tourStatusId: tourStatusFilter.value,
      tourTypeId: tourTypeFilter.value,
    };

    getTours(params)
      .then((response) => {
        const { tours, total } = response;
        callback(tours);
        totalTours.value = total;
      })
      .catch(() => {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'Error fetching tours list',
            icon: 'AlertTriangleIcon',
          },
        });
      });
  };

  return {
    fetchTours,
    tableColumns,
    pageSize,
    pageNumber,
    totalTours,
    dataMeta,
    perPageOptions,
    searchQuery,
    searchByDate,
    orderBy,
    orderDesc,
    refTourListTable,
    tourStatusFilter,
    tourTypeFilter,
    clearSearchByDate,
  };
}
