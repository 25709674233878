<template>
  <b-card no-body>
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-75"
        >
          <label>Show</label>
          <v-select
            v-model="pageSize"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <label>entries</label>
        </b-col>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-start justify-content-sm-end"
        >

          <b-form-group>
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Press ENTER to search"
              @keyup.enter="refTourListTable.refresh()"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <div class="d-flex flex-md-row flex-column align-items-start justify-content-end w-100 px-1 px-md-0">
          <div class="d-flex d-flex-row">
            <flat-pickr
              v-model="searchByDate"
              class="form-control"
              :config="{ dateFormat: 'd/m/Y', enableTime: false }"
              readonly="false"
              placeholder="Date"
            />
            <b-button
              v-if="searchByDate !== ''"
              variant="flat-secondary"
              class="btn-icon ml-50"
              @click="clearSearchByDate"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </div>

          <v-select
            v-model="tourStatusFilter"
            :reduce="(status) => status.id"
            :options="tourStatus"
            class="tour-filter-select my-50 my-md-0 mx-md-1"
            placeholder="Select Status"
            label="name"
          >
            <template #selected-option="{ name }">
              <span class="text-truncate overflow-hidden">
                {{ name }}
              </span>
            </template>

            <template #option="{ name }">
              <span class="text-truncate overflow-hidden">
                {{ name }}
              </span>
            </template>
          </v-select>

          <v-select
            v-model="tourTypeFilter"
            :reduce="(type) => type.id"
            :options="tourTypes"
            class="tour-filter-select"
            placeholder="Select Type"
            label="name"
          >
            <template #selected-option="{ name, rate }">
              <span class="text-truncate overflow-hidden">
                {{ name }}
              </span>

              <span class="text-truncate text-muted overflow-hidden">
                (£{{ rate }})
              </span>
            </template>

            <template #option="{ name, rate }">
              <span class="text-truncate overflow-hidden">
                {{ name }}
              </span>

              <span class="text-truncate text-muted overflow-hidden">
                (£{{ rate }})
              </span>
            </template>
          </v-select>
        </div>
      </b-row>
    </div>

    <b-table
      ref="refTourListTable"
      :items="fetchTours"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="orderBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="orderDesc"
      class="position-relative"
    >
      <template #cell(voucherNum)="data">
        <b-link
          :to="{
              name: 'frontier-tour-detail',
              params: { id: data.item.id },
            }"
          class="font-weight-bold"
        >
          {{ data.value }}
        </b-link>
      </template>

      <template #cell(tourType)="data">
        <b-badge
          :variant="`light-primary`"
          class="text-capitalize"
        >
          {{ data.item.tourType.name }}
        </b-badge>
      </template>

      <template #cell(tourStatusId)="data">
        <b-badge
          :variant="`light-${getTourStatusVariant(data.item.tourStatus)}`"
          class="text-capitalize"
        >
          {{ data.item.tourStatus.name }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <template v-if="!data.item.tourStatus.isEdited && !data.item.tourStatus.isCancelled">
            <feather-icon
              :id="`tour-row-${data.item.id}-edit`"
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="$router.push({
                  name: 'frontier-tour-edit',
                  params: { id: data.item.id },
                })
                "
            />

            <b-tooltip
              title="Edit Tour"
              class="cursor-pointer"
              :target="`tour-row-${data.item.id}-edit`"
            />
          </template>

          <feather-icon
            :id="`tour-row-${data.item.id}-detail`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({
                name: 'frontier-tour-detail',
                params: { id: data.item.id },
              })
              "
          />

          <b-tooltip
            title="Preview Tour"
            :target="`tour-row-${data.item.id}-detail`"
          />
        </div>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-center justify-content-md-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-center justify-content-md-end"
        >
          <b-pagination
            v-model="pageNumber"
            :total-rows="totalTours"
            :per-page="pageSize"
            first-number
            last-number
            class="mb-0 mt-1 mt-md-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { avatarText } from '@core/utils/filter';
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BFormGroup, BPagination, BTooltip } from 'bootstrap-vue';
import { ref } from 'vue-demi';
import flatPickr from 'vue-flatpickr-component';
import useTourList from '@/modules/frontier-tour/composables/useTourList';
import useTourStatus from '@/modules/frontier-tour/composables/useTourStatus';
import useTourType from '@/modules/frontier-tour-settings/composables/useTourType';
import useTourUtils from '@/modules/frontier-tour/composables/useTourUtils';
import vSelect from 'vue-select';

export default {
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BLink,
    BMedia,
    BPagination,
    BRow,
    BTable,
    BTooltip,
    vSelect,
    flatPickr,
  },
  setup() {
    const {
      fetchTours,
      tableColumns,
      pageSize,
      pageNumber,
      totalTours,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchByDate,
      orderBy,
      orderDesc,
      refTourListTable,
      tourStatusFilter,
      tourTypeFilter,
      clearSearchByDate,
    } = useTourList();

    const { getTourStatusList } = useTourStatus();
    const { getTourTypeList } = useTourType();
    const { getTourStatusVariant } = useTourUtils();

    const tourTypes = ref([]);
    const tourStatus = ref([]);

    const initData = async () => {
      const typeList = await getTourTypeList();
      const statusList = await getTourStatusList();
      tourTypes.value = typeList.tourTypes;
      tourStatus.value = statusList;
    }

    initData();

    return {
      searchByDate,
      avatarText,
      dataMeta,
      fetchTours,
      getTourStatusVariant,
      orderBy,
      orderDesc,
      pageNumber,
      pageSize,
      perPageOptions,
      refTourListTable,
      searchQuery,
      tableColumns,
      totalTours,
      tourStatus,
      tourStatusFilter,
      tourTypeFilter,
      tourTypes,
      clearSearchByDate
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.tour-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
